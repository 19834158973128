
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { SystemStatusProvider } from '@/contexts/SystemStatusContext'
import { ViewportProvider } from '@/contexts/ViewportContext'
import { GoogleTagManager } from '@next/third-parties/google'
import { getTensorFonts } from 'fonts'
import { formatUrl } from 'format'
import { DefaultSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { RouterProvider as AriaRouterProvider } from 'react-aria'
import { Provider as BalanceProvider } from 'react-wrap-balancer'
import { SentryProvider } from 'src/contexts/SentryContext'
import 'stylesheets/tensor-website-baseline.css'
import { makeLocalizedWebsiteTheme } from 'theme'
import { AppPropsWithLayout } from 'typ'
import { ThemeProvider } from 'ui'

const fonts = getTensorFonts()

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  const { lang } = useTranslation()
  const isJp = !lang || lang === 'ja'
  const theme = makeLocalizedWebsiteTheme(lang, {
    fontFamily: fonts.ibmPlexSans.style.fontFamily,
  })
  const router = useRouter()
  const useHref = useCallback((href: LinkProps['href']) => {
    if (typeof href === 'string') {
      return href
    }
    return formatUrl(href)
  }, [])

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <ThemeProvider theme={theme}>
        <ViewportProvider>
          <SentryProvider>
            <BalanceProvider>
              <SystemStatusProvider>
                <DefaultSeo
                  title={
                    isJp
                      ? 'Tensor Cloud - 再生可能エネルギーの持続可能な成長を支えるプラットフォーム ⚡️'
                      : 'Tensor Cloud - The leading renewable energy platform ⚡️'
                  }
                  description={
                    isJp
                      ? 'Tensor Cloudは再生可能エネルギー発電所の開発、資産管理、運用最適化と効率化を支援します。もっと早くもっと賢く効率良く。スピードとスケーラビリティを追求してデザインされた唯一無二のオーケストレーションプラットフォームです'
                      : 'Tensor Energy is a technology startup that helps companies make the most out of their renewable energy assets, operationally and financially.'
                  }
                  openGraph={{
                    locale: isJp ? 'ja_JP' : 'en_US',
                    siteName: isJp ? 'テンサーエナジー' : 'Tensor Energy',
                    images: [
                      {
                        url: isJp
                          ? 'https://www.tensorenergy.jp/images/og-image-01-jp.png'
                          : 'https://www.tensorenergy.jp/images/og-image-01-en.png',
                        width: 1200,
                        height: 666,
                        alt: isJp
                          ? 'Tensor Cloud - 再生可能エネルギーの持続可能な成長を支えるプラットフォーム ⚡️'
                          : 'Tensor Cloud - The leading renewable energy platform ⚡️',
                        type: 'image/png',
                      },
                    ],
                  }}
                  additionalMetaTags={[
                    {
                      property: 'og:locale:alternate',
                      content: isJp ? 'en_US' : 'ja_JP',
                    },
                  ]}
                />
                <AriaRouterProvider
                  navigate={(href, opts) => router.push(href, undefined, opts)}
                  useHref={useHref}
                >
                  {getLayout(<Component {...pageProps} />)}
                </AriaRouterProvider>
                <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_KEY ?? ''} />
              </SystemStatusProvider>
            </BalanceProvider>
          </SentryProvider>
        </ViewportProvider>
      </ThemeProvider>
    </>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  