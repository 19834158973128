/* eslint-disable sonarjs/no-duplicate-string */
const config = {
  locales: ['ja', 'en'],
  defaultLocale: 'ja',
  loadLocaleFrom: (lang, ns) =>
    import(`../../packages/locales/${lang}/${ns}.json`).then((m) => m.default),
  pages: {
    '*': ['tensor-website-common', 'errors', 'validation'],
    '/404': ['page-titles'],
    '/500': ['page-titles'],
    '/blocked': ['page-titles'],
    '/': ['tensor-website-landing'],
    '/contact': ['tensor-website-contact'],
    'rgx:^/features': ['tensor-website-features'],
    '/pricing': ['tensor-website-pricing'],
    '/company': ['tensor-website-company'],
    'rgx:^/insights': ['tensor-website-insights'],
    '/press': ['tensor-website-press'],
    '/features/development': ['tensor-website-feat-development'],
    '/features/management': ['tensor-website-feat-management'],
    '/features/operations': ['tensor-website-feat-operations'],
    '/features/security': ['tensor-website-feat-security'],
    '/fip': ['tensor-website-fip-calculator'],
    '/battery-storage-optimization': ['tensor-website-battery-storage-optimization'],
  },
}

module.exports = config
